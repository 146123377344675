// frontend/src/components/Footer.tsx
import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-black-200 text-gray-200 p-4 text-center">
      <p>
        &copy; {currentYear} TheForkBabyExperiment LLC. All rights reserved.
        Website Powered By Armant T.
      </p>
      {/* Optionally, you can add state of registration */}
      {/* <p>Registered in [State Name]</p> */}
    </footer>
  );
};

export default Footer;
