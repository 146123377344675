import React from "react";
import {
  FaInstagram,
  FaLink,
  FaYoutube,
  FaXTwitter,
  FaFacebook,
} from "react-icons/fa6"; // Using fa6 as per your code

import ava_pic from "assets/img/ava_pic.jpeg";
import ed_pic from "assets/img/ed_pic.jpeg";
import zoe_pic from "assets/img/zoe_pic1.png";

// --- DEFINE A REUSABLE TYPE for Social Links ---
// This allows each property to be either a string (URL) or null
type SocialLinks = {
  youtube: string | null;
  instagram: string | null;
  x: string | null;
  facebook: string | null;
  website: string | null;
  // Add other potential platforms here as 'string | null' if needed
};

const AboutPage: React.FC = () => {
  // Define the socials object - explicitly typed using SocialLinks (optional but good)
  // Using the data structure you provided with nulls for Ava/Edison YouTube
  const socials: { zoe: SocialLinks; ava: SocialLinks; edison: SocialLinks } = {
    zoe: {
      youtube: "https://www.youtube.com/@missmothra",
      instagram: null,
      x: null,
      facebook: null,
      website: null,
    },
    ava: {
      youtube: "https://www.youtube.com/channel/UCxGz00F6gxyiMx6t5BLkn6Q", // This is null as per your provided code
      instagram: null,
      x: null,
      facebook: null,
      website: null,
    },
    edison: {
      youtube: null, // This is null as per your provided code
      instagram: null,
      x: null,
      facebook: null,
      website: null,
    },
  };

  // --- UPDATE FUNCTION SIGNATURE ---
  // Helper function now uses the flexible 'SocialLinks' type for its parameter
  const renderSocialLinks = (artistSocials: SocialLinks) => (
    <div className="flex justify-center space-x-4 my-2">
      {/* YouTube Icon */}
      {artistSocials.youtube && ( // Only renders if youtube is a non-null string
        <a
          href={artistSocials.youtube} // TS knows this is a string here because of the check above
          target="_blank"
          rel="noopener noreferrer"
          aria-label="YouTube"
          title="YouTube"
        >
          {/* TS2786 Error likely still occurs here until versions are fixed */}
          <FaYoutube className="text-2xl text-gray-500 hover:text-red-600 transition-colors" />
        </a>
      )}
      {/* Instagram Icon */}
      {artistSocials.instagram && (
        <a
          href={artistSocials.instagram}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
          title="Instagram"
        >
          {/* TS2786 Error likely still occurs here */}
          <FaInstagram className="text-2xl text-gray-500 hover:text-pink-600 transition-colors" />
        </a>
      )}
      {/* X Icon */}
      {artistSocials.x && (
        <a
          href={artistSocials.x}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="X Profile"
          title="X"
        >
          {/* TS2786 Error likely still occurs here */}
          <FaXTwitter className="text-2xl text-gray-500 hover:text-black transition-colors" />
        </a>
      )}
      {/* Facebook Icon */}
      {artistSocials.facebook && (
        <a
          href={artistSocials.facebook}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
          title="Facebook"
        >
          {/* TS2786 Error likely still occurs here */}
          <FaFacebook className="text-2xl text-gray-500 hover:text-blue-600 transition-colors" />
        </a>
      )}
      {/* Website Icon */}
      {artistSocials.website && (
        <a
          href={artistSocials.website}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Website"
          title="Website/Portfolio"
        >
          {/* TS2786 Error likely still occurs here */}
          <FaLink className="text-2xl text-gray-500 hover:text-gray-800 transition-colors" />
        </a>
      )}
    </div>
  );

  return (
    <div className="p-4 text-gray-200">
      {/* About the Experiment Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-center mb-4">
          ABOUT THE EXPERIMENT
        </h2>
        <div className="flex flex-wrap text-center justify-around gap-8">
          {/* Zoe Section */}
          <div className="p-4 rounded-lg hover:shadow-md transition-shadow duration-200 hover:bg-neutral-800 flex flex-col items-center max-w-sm">
            <img
              src={zoe_pic}
              alt="Zoë's Headshot"
              className="w-50 h-48 object-cover rounded-md mb-2"
            />
            <h3 className="text-xl font-semibold mt-2 mb-1">Zoë</h3>
            {/* This call is now type-correct (TS2345 fixed) */}
            {renderSocialLinks(socials.zoe)}
            <div className="text-gray-200 w-full">
              <p className="text-left indent-4">
                Zoë is a mythopoetic storyteller, a visual alchemist and a
                frequent flyer in the world of dreams. The discoveries she makes
                from her dreams inform the esoteric symbolism present in her
                various artistic works.
              </p>
              <p className="text-left indent-4">
                Her process of creation involves experimentation, use of
                metaphor and introspection. Some of her goals are focused around
                methodically connecting people through animated visuals with use
                of unconventional modalities; while other goals involve
                squashing all expectations; allowing chaos and disconnect to run
                their course. There are prevalent themes of metamorphosis,
                rebirth and transformation in her life and creative exercises.
                The full range of human experience can be found in a film or a
                teardrop, and it’s in capturing this and sharing it that we find
                our solidarity as humans.
              </p>
              <p className="text-left indent-4">
                Curiosity and open-mindedness are keys that open doors to Zoë
                within her practice. It’s essential; after all, even the most
                esoteric of symbols are sometimes not enticing enough to draw
                the visual alchemist out of their comfort zone and into the
                egregore. Sometimes it takes a special kind of radical curiosity
                to find those secrets that lie in wait.
              </p>
            </div>
          </div>

          {/* Ava Section */}
          <div className="p-4 rounded-lg hover:shadow-md transition-shadow duration-200 hover:bg-neutral-800 flex flex-col items-center max-w-sm">
            <img
              src={ava_pic}
              alt="Ava's Headshot"
              className="w-50 h-48 object-cover rounded-md mb-2"
            />
            <h3 className="text-xl font-semibold mt-2 mb-1">Ava</h3>
            {/* This call is now type-correct (TS2345 fixed) */}
            {renderSocialLinks(socials.ava)}
            <div className="text-gray-200 w-full">
              <p className="text-left indent-4 whitespace-break-spaces">
                Hailing from the San Diego Coast, Ava made her way up to
                Portland in pursuit of a degree in 2D Animation. She sifted
                through all different types of mediums before landing on
                Stop-motion as her main focus. Her indecision ended up lending
                nicely to a medium that requires all types of different skill
                sets.
              </p>
              <p className="text-left indent-4 whitespace-break-spaces">
                In the making of her second short film Ava took on a side quest
                of animating a film in 48hrs with none other than Edison and
                Zoë. The rest was history. Away from the studio you can find Ava
                hangin’ with her buddy Clancy the dog, playing piano or groovin’
                to some jams!
              </p>
            </div>
          </div>

          {/* Edison Section */}
          <div className="p-4 rounded-lg hover:shadow-md transition-shadow duration-200 hover:bg-neutral-800 flex flex-col items-center max-w-sm">
            <img
              src={ed_pic}
              alt="Edison's Headshot"
              className="w-50 h-48 object-cover rounded-md mb-2"
            />
            <h3 className="text-xl font-semibold mt-2 mb-1">Edison</h3>
            {/* This call is now type-correct (TS2345 fixed) */}
            {renderSocialLinks(socials.edison)}
            <div className="text-gray-200 w-full">
              <p className="text-left indent-4">
                Born and raised on the East Coast, Edison was the last Fork to
                find animation. He received a Bachelors in Botany before moving
                to Portland to pursue a Post-Bacc in Stop-Motion Animation.
              </p>
              <p className="text-left indent-4">
                Edison is particularly interested in found objects and
                surrealist animation. His work typically involves bright color
                palettes and textures along with theatrical lighting and themes.
                His favorite medium is felt. In his free time Edison enjoys
                riding the local light rail and sketching out the burning
                leaves.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Media Statement and Studio Manifesto - Metal Gore Style */}
      <div className="max-w-3xl mx-auto my-8 px-4">
        <figure>
          <blockquote
            className={
              "border-l-4 border-red-800 " + // Dark red left border
              "bg-neutral-900 " + // Very dark gray/off-black background (optional, remove if pure black needed)
              "p-4 " + // Padding
              "italic text-neutral-200" // Italic, off-white/light gray text for readability
              // Removed rounded-r-lg
              // Removed shadow-md, hover:shadow-lg, transition, duration
            }
          >
            The Fork Baby Experiment is an artists’ collective, based out of
            Portland, Oregon. The Fork Baby Experiment (FBE) works to produce
            stop-motion and experimental short films. Within FBE we push each
            other to create exciting new work in a flexible, cooperative and
            creative-led environment. The artists who make up FBE each have
            unique perspectives and priorities in film-making, which come across
            in their projects. As a collection our work is darkly comedic and
            surrealist. We also intentionally showcase the handmade aspect of
            our craft. Each puppet, set and indeed most shots are all made by
            hand with very few digital graphic edits. FBE does not use AI
            generated images.
          </blockquote>
          <figcaption
            className={
              "mt-3 pr-2 text-right text-sm " + // Existing layout classes
              "text-gray-400" // Dimmer light gray caption text
            }
          >
            — <cite className="font-semibold not-italic">The FBE Founders</cite>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default AboutPage;
