import React from "react";
import { createRoot } from "react-dom/client"; // Correctly import createRoot
import "./assets/styles/index.css"; // Tailwind CSS import
import "./assets/styles/App.css"; // Custom CSS import
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!); // TypeScript may require a non-null assertion

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
