import React from "react";
import { Link } from "react-router-dom";

import Logo from "assets/img/FBE_LOGO.png";

// Todo: have a tongue img stretched out and each Link and Logo are squared like acid tabs

const Navbar: React.FC = () => {
  return (
    <nav className="bg-black-100 p-4">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="flex-grow flex justify-center md:flex-grow-0">
          <img src={Logo} alt="Logo" className="rounded-br-lg h-16" />{" "}
          {/* Adjust height as needed */}
        </div>
        <Link
          to="/"
          className="text-gray-200 sm:py-4 font-bold hover:text-red-500 transition-colors"
        >
          THE FORK BABY EXPERIMENT
        </Link>
        <div className="flex items-center space-x-4">
          <Link
            to="/about"
            className="text-gray-200 sm:py-4 font-bold hover:text-red-500 transition-colors"
          >
            ABOUT
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
