import React from "react";

const youtubeVideoUrls = [
  { url: "https://www.youtube.com/embed/7w4q9-DeuSo", title: "FORK BABY" },
  {
    url: "https://www.youtube.com/embed/o0Hg7AVh6HY",
    title: "Scarlett Staccato ",
  },
];

const HomePage: React.FC = () => {
  // Get the most recent video URL (first in the array)
  const mostRecentVideo = youtubeVideoUrls[0];

  // Get the remaining videos for the gallery
  // Ensure slice takes videos *after* the first one
  const videoGalleryVideos = youtubeVideoUrls.slice(1, 5); // Get up to 4 videos for gallery (index 1, 2, 3, 4)

  return (
    <div className="p-4">
      {/* Container for the page content, add padding */}

      {/* Main Video Section */}
      <div className="mb-6 md:mb-16">
        {/* Increased margin-bottom on desktop */}
        <div className="text-center mb-4">
          <p className="text-lg text-gray-200 font-semibold">
            MOST RECENT SHORT
          </p>
        </div>
        <div className="w-full aspect-w-16 aspect-h-9 md:max-w-3xl md:mx-auto">
          {/* Added max-width and mx-auto on desktop */}
          {mostRecentVideo ? (
            <>
              <iframe
                className="w-full h-full"
                src={mostRecentVideo.url}
                title={mostRecentVideo.title}
                allowFullScreen
                // Consider adjusting height or using aspect ratio classes for responsiveness
                // style={{ height: "500px" }} // Removed fixed height for better responsiveness
              />
              <p className="mt-2 text-md font-medium text-center">
                {mostRecentVideo.title}
              </p>
            </>
          ) : (
            <div className="bg-gray-200 flex items-center justify-center aspect-video">
              {" "}
              {/* Added aspect-video */}
              No recent video available
            </div>
          )}
        </div>
      </div>

      {/* Video Gallery */}
      <div className="mb-6 md:mb-16">
        {/* Increased margin-bottom on desktop */}
        <div className="text-center mb-4">
          <p className="text-lg text-gray-200 font-semibold">VIDEO GALLERY</p>
        </div>
        {/* --- CHANGE IS HERE --- */}
        <div className="grid grid-cols-1 gap-4 max-w-md mx-auto">
          {/* Changed grid-cols-2 to grid-cols-1 for mobile default */}
          {/* Removed md: prefixes as it's now 1 column always */}
          {/* Added max-w-md and mx-auto to constrain width and center */}
          {videoGalleryVideos.map((video, index) => (
            <div
              key={index}
              // Removed aspect ratio classes from here, applied to iframe parent div
              className="w-full mb-4 md:mb-8" // Keep margin-bottom
            >
              {/* Wrap iframe in aspect ratio div */}
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  className="w-full h-full"
                  src={video.url}
                  title={video.title}
                  allowFullScreen
                  // style={{ height: "300px" }} // Removed fixed height
                />
              </div>
              <p className="mt-2 text-md font-medium text-center">
                {video.title}
              </p>
            </div>
          ))}
        </div>
        {videoGalleryVideos.length === 0 && (
          <div className="text-center text-lg text-gray-200 font-semibold mt-4">
            {" "}
            {/* Added margin-top */}
            More to Come!
          </div>
        )}
      </div>

      {/* Social Icons Footer */}
      {/* Container keeps centering, auto margin, and padding */}
      <div className="flex justify-center mt-auto pt-8 pb-4">
        {/* Removed INSTA div and space-x-4 from container */}

        {/* YouTube Link */}
        <a
          href="https://youtube.com/@forkbabyexperiment?si=RrfJUZF0YxQQBObp" // Your specified URL
          target="_blank" // Open in new tab
          rel="noopener noreferrer" // Security measure for target="_blank"
          // Apply Tailwind classes for styling: Red, Uppercase, Hover effect
          className="text-red-500 uppercase font-semibold hover:text-red-400 transition-colors"
        >
          YOUTUBE
        </a>
      </div>
      {/* --- END SOCIAL ICONS UPDATE --- */}
    </div>
  );
};

export default HomePage;
